.order {

    &-table--wrapper {
        display: flex;
    }

    .table-container {
        border-bottom: 1px solid var(--boxcolor);
        margin-bottom: 8px;
        padding: 0 20px;
        position: relative;
        z-index: 0;
    }

    .table-wrapper {
        max-height: 200px;

        th {
            position: sticky;
            top: 0;
            background-color: var(--basecoloralt);
            z-index: 3;
        }
    }

    table {
        font-size: 14px;

        th,
        td {
            padding: 0 10px;
            text-align: left;
        }

        th {
            padding: 10px;
        }

        .order-row {
            cursor: pointer;

            &:hover {
                background-color: var(--boxcolor);
            }

            &--selected {
                background-color: var(--boxcolor);
            }
        }
    }

    &-info {
        display: flex;
        justify-content: space-between;
        background-color: var(--basecoloralt);
        border-radius: 10px;
        padding: 10px;
        padding-bottom: 0;
        margin: 0 20px;

        &--left,
        &--right {
            flex: 1;
        }

        &--left {
            margin-right: 20px;
        }

        .input-wrapper {
            margin-bottom: 8px;
        }
    }

}

.box-fields {
    margin-bottom: 10px;

    &--main {
        background-color: var(--basecoloralt);
        padding: 10px 10px 4px 10px;
        border-radius: 10px;
        margin-right: 10px;
    }

    .input-wrapper {
        margin-bottom: 8px;
    }

    &--description {
        display: flex;
        align-items: center;

        .input-wrapper {
            flex: 1;
        }

        .btn {
            font-size: 14px;
            margin-left: 10px;
        }
    }

    &--constructor {
        display: flex;

        & > div {
            &:first-child {
                margin-right: 20px;
            }
        }
    }

    &--tabs {
        padding: 0 20px;

        &-header {
            display: flex;
            justify-content: space-between;
            position: sticky;
            width: 100%;
            background-color: var(--basecolor);
            z-index: 3;
            top: 0;
            border: 2px solid var(--boxcolor);
            border-radius: 6px;

            &-item {
                cursor: pointer;
                flex: 1;
                text-align: center;
                padding: 4px 8px;
                position: relative;
                border-right: 2px solid var(--boxcolor);
                font-size: 12px;

                &:last-child {
                    border-right: none;
                }

                &.active {
                    background-color: var(--basecoloralt);
                    color: #242425;
                    color: var(--primarylight);
                }
            }
        }

        &-workflow {
            h5 {
                margin-top: 0;
            }

            .box-fields--workflow {
                background-color: var(--basecoloralt);
                border-radius: 10px;
                padding: 20px;
                display: grid;
                grid-template-columns: 1fr 1fr;

                .input-wrapper {

                    &--checkbox {
                        padding: 6px 0;
                        label {
                            align-items: center;
                        }
                    }
                }
            }
        }

        &-project {
            .box-material-fields--workflow {
                padding: 20px;
                background-color: var(--basecoloralt);
                border-radius: 10px;

                h5 {
                    margin-top: 0;
                }

                .field-row {
                    display: block;

                    .field-col {

                        &:last-child {
                            margin-top: 20px;
                        }
                    }
                }

                .input-wrapper {

                    &--checkbox {
                        padding: 6px 0;
                        label {
                            align-items: center;
                        }
                    }
                }
            }
            .box-fields {
                
                &--box {
                    padding: 20px;
                    background-color: var(--basecoloralt);
                    border-radius: 10px;

                    .field-row {
                        .field-col {
                            &:first-child {
                                margin-right: 20px;
                            }
                        }
                    }

                    .box-type {
                        .input-wrapper {
                            width: 100%;
                        }
                    }
                }

                &--colors {
                    padding-top: 0;
                }

                &--measurements {
                    padding: 20px;
                    background-color: var(--basecoloralt);
                    border-radius: 10px;

                    &-fields {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        &-content {
            display: none;
            padding: 5px 0 0;

            .field-row {

                &--calculations {
                    background-color: var(--basecoloralt);
                    border-radius: 10px;
                    align-self: flex-start;
                }
            }

            &.show {
                display: block;
            }

            &--order {

                &.show {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    &--totals {
        display: flex;
        justify-content: space-between;
        background-color: var(--basecoloralt);
        border-radius: 10px;
        padding: 10px;

        h5 {
            margin-top: 0;
            margin-bottom: 5px;
        }

        & > div {
            flex: 1;
        }

        .btn {
            width: 100%;
        }
    }

    &--colors {
        padding: 20px 20px 4px;
        border-radius: 10px;
        background-color: var(--basecoloralt);
        margin-top: 20px;

        h5 {
            margin-top: 0;
        }

        & > div {
            display: flex;
            justify-content: space-between;
        }

        &-column {
            flex: 1;

            &:first-child {
                margin-right: 20px;
            }
        }
    }

    &--measurements {
        margin: 0;
        padding-left: 30px;

        & > span {
            display: block;
            margin-bottom: 5px;
        }

        &-fields {
            display: flex;
            align-items: center;

            .input-wrapper {
                margin-bottom: 0;
                margin-right: 10px;
            }

            .divider {
                padding: 0 10px 0 0;
            }
        }
    }

    &--measurement {
        display: flex;
        margin-bottom: 10px;
        font-size: 14px;

        span {

            &:first-child {
                margin-right: 10px;
                width: 100%;
                max-width: 150px;
            }
        }
    }

    &--row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > * {
            flex: 1;

            &:first-child {
                margin-right: 20px;
            }
        }
    }

    &--footer {
        display: flex;
        justify-content: space-between;

        & > div {
            flex: 1;
            border-radius: 10px;
            border: 2px solid var(--boxcolor);
            padding: 20px;

            &:first-child {
                margin-right: 20px;
            }

            h3 {
                margin: 0 0 30px;
            }
        }
    }

    &--material {
        display: flex;
        align-items: center;

        .input-wrapper {
            flex: 1;
            margin-right: 20px;
        }

        &-tab {
            .field-row {
                background-color: var(--basecoloralt);
                padding: 20px 20px 4px 20px;
                border-radius: 10px;

                .field-col {
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    &--spread {
        display: flex;
        margin-top: 16px;

        &-result {
            display: flex;
            align-items: center;
            flex: 1;
        }

        &-input {
            display: flex;
            align-items: center;
            margin-right: 20px;

            & > span {
                margin: 0 4px;
            }

            .input-wrapper {
                margin-bottom: 0;
            }
        }
    }
}

.box-price-fields {

    &--items {
        .input-wrapper {
            margin-bottom: 8px;
            width: 100%;

            label {
                display: flex;
                align-items: center;

                span {

                    &:first-child {
                        margin-right: 10px;
                        width: 100%;
                        max-width: 150px;
                    }
                }
            }
        }
    }

    &--item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-size: 14px;

        span {
            &:first-child {
                margin-right: 10px;
                width: 100%;
                max-width: 150px;
            }
        }

        .calculate-price {
            font-size: 11px;
            margin-bottom: 5px;
        }
    }
}

#modal .modal-production {
    max-width: 400px;
    
    .modal-content {
        min-height: 440px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.files-upload {
    .empty-result {
        margin: 20px 0 40px;
    }
}