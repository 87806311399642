h2 {
    font-size: 18px;
    margin-bottom: 40px;
}

a {
    text-decoration: none;
    color: var(--primarylight);
}

span {

    &.input-label {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
    }
}

.btn {
    background-color: var(--primarylight);
    color: var(--buttoncolor);
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color .08s ease;
    -webkit-appearance: none;
    border: none;
    box-shadow: none;
    font-size: 16px;

    &:hover {
        background-color: var(--primary);
    }

    &-cancel {
        background-color: var(--primarydark);
    }

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        &--icon {
            flex: 0 0 14px;
            height: 14px;
            margin-right: 6px;

            svg {
                fill: var(--basecolor);

                .theme--dark & {
                    fill: var(--fontcolor)
                }
            }
        }

        &--label {
            font-size: 12px;
            white-space: nowrap;
        }
    }

    &-back {
        background-color: transparent;
        color: var(--primary);
        padding: 0;
        display: flex;
        align-items: center;
        font-weight: bold;

        span {
            transform: translate3d(0, 0, 0);
            transition: transform .2s ease;
        }

        .btn-icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;

            svg {
                fill: var(--primary);
            }
        }

        &:hover {
            background-color: transparent;
            color: var(--primarylight);

            svg {
                fill: var(--primarylight);
            }

            span {
                transform: translate3d(-3px, 0, 0);
            }
        }
    }

    &:disabled {
        background-color: var(--bordercoloralt);
        cursor: default;
    }
}

.empty-result {
    margin-bottom: 16px;
    color: rgb(111, 113, 116);
}

.notifications {
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 100;
    width: 100%;
    pointer-events: none;

    @keyframes dropIn {
        0% {
            transform: translate3d(-50%, -200px, 0);
        }
        100% {
            transform: translate3d(-50%, 0, 0);
        }
    }

    .notification {
        background-color: var(--basecolor);
        width: 100%;
        max-width: 300px;
        border-radius: 6px;
        padding: 10px 0 10px 20px;
        box-shadow: 0 3px 16px rgba(black, .1);
        position: relative;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        margin-bottom: 10px;
        cursor: pointer;
        animation: dropIn .2s ease forwards;
        display: flex;
        align-items: center;
        pointer-events: all;

        &::before {
            content: '';
            display: block;
            width: 8px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        &-inner {
            flex: 1;
        }

        &-content {
            font-size: 14px;
            color: rgb(111, 113, 116);
        }
        
        &-error {

            &::before {
                background-color: rgb(255, 0, 85);
            }
        }

        &-success {

            &::before {
                background-color: rgb(22, 185, 123);
            }
        }


        &-info {

            &::before {
                background-color: rgb(22, 144, 205);
            }
        }

        &-close-wrapper {
            border-left: 1px solid var(--boxcolor);
            position: relative;
            flex: 0 0 44px;
            padding-right: 5px;
            height: 30px;

        }

        &-close {
            width: 20px;
            height: 20px;
            position: absolute;
            -webkit-appearance: none;
            background: none;
            border: none;
            box-shadow: none;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            cursor: pointer;
        
            &::after,
            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background-color: var(--bordercoloralt);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }
        
            &::before {
              transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            }
        
            &:hover {
              opacity: .6;
            }
        }
    }
}

.loading-view {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.file-input {
    span {
        color: var(--basecolor);
        background-color: var(--primarylight);
        padding: 8.25px 16px;
        border-radius: 6px;
        display: inline-block;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            background-color: var(--primary);
        }
    }

    &--filename {
        font-size: 14px;
    }

    input {
        position: absolute;
        opacity: 0;
        pointer-events: none;
    }
}

.upload-file {
    max-width: 400px;

    &--type {
        display: flex;
        align-items: flex-end;

        .input-wrapper {
            margin-bottom: 0;
            width: 200px;
        }
    }

    &--filename {
        font-size: 14px;
        margin: 10px 0;
    }

    .file-input {
        margin-left: 20px;

        &--filename {
            display: none;
        }
    }
}

.input-wrapper {
    margin-bottom: 16px;

    label {
        span {
            display: block;
            margin-bottom: 5px;
            font-size: 12px;
        }
    }

    &--input {
        width: 100%;
    }

    .css-at12u2-loadingIndicator,
    .css-1xh8qep-loadingIndicator {
        span {
            font-size: 4px;
        }
    }

    .css-1pahdxg-control {
        box-shadow: 0 0 0 1px var(--primarylight);
        border-color: var(--primarylight);

        &:hover {
            border-color: var(--primarylight);
        }
    }
    
    .css-26l3qy-menu {
        z-index: 999;
        background-color: var(--basecolor);
    }
    
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        min-height: 30px;
        background-color: var(--basecolor);
        border-color: var(--bordercoloralt);

        &:hover {
            border-color: var(--bordercoloralt);
        }
    }

    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .css-qc6sy-singleValue {
        font-size: 14px;
        color: var(--fontcolor);
    }

    .css-9gakcf-option {
        background-color: var(--primarylight);
    }

    .css-1n7v3ny-option {
        background-color: var(--boxcolor);
    }

    input:not([type=checkbox]),
    textarea {
        display: block;
        width: 100%;
        height: 100%;
        padding: 7px 10px;
        border-radius: 4px;
        -webkit-appearance: none;
        border: 1px solid var(--bordercoloralt);
        background-color: var(--basecolor);
        color: var(--fontcolor);
        transition: border-color .2s ease;
        font-size: 14px;
        font-family: 'Ubuntu', sans-serif;

        &:focus {
            outline: 0;
        }
    }

    textarea {
        resize: vertical;
        min-height: 120px;
    }

    &--control {
        display: flex;
        align-items: center;

        &-focused {
            .input-wrapper--input {
                position: relative;
                box-shadow: 0 0 0 1px var(--primarylight);
                border-color: var(--primarylight);
                border-radius: 4px;

                input,
                textarea {
                    border-color: var(--primarylight);
                }
            }
        }
    }

    &--suffix {
        margin-left: 10px;
    }

    &--checkbox {
        margin-bottom: 0;

        &-box {
            width: 100%;
            height: 100%;
            position: relative;
            background-color: var(--basecolor);
            width: 30px;
            height: 30px;
            border-radius: 4px;
            border: 1px solid var(--bordercolor);

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0) scale(0);
                pointer-events: none;
                opacity: 0;
                transition: all .12s ease-in-out;
                fill: var(--primarylight);
            }
        }

        &-disabled {

            .input-wrapper--checkbox-box {
                background-color: var(--boxcolor);
            }
            svg {
                fill: var(--bordercoloralt);
            }
        }

        &-checked {
            svg {
                opacity: 1;
                transform: translate3d(-50%, -50%, 0) scale(1);
            }
        }

        label {
            display: flex;
            align-items: center;

            input {
                opacity: 0;
                position: absolute;
                pointer-events: none;
            }

            span {
                margin-bottom: 0;
                margin-left: 8px;
            }
        }
    }

    &--error {

        label {
            & > span {
                color: rgb(255, 0, 85);
            }
        }
        
        .input-error {
            font-size: 14px;
            color: var(--bordercoloralt);
            padding: 6px 0 0;
        }
    }
}

.field-row {
    display: flex;
    justify-content: space-between;

    .field-col {
        flex: 1;
    }

    @media (max-width: 991px) {
        display: block;
    }
}

.errors {
    &-message {
        color: var(--primarylight);
    }
}

.field-row,
.field-col {
    & > span {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
    }
}

.grid-list {

    &-item {
        display: grid;
        font-size: 14px;
        padding: 8px;

        &:not(.grid-list-item--header) {
            cursor: pointer;

            &:hover {
                background-color: #f1f3f7;
            }
        }

        &:nth-child(even) {
            background-color: rgb(249, 251, 252);
        }

        &--col {
            text-align: center;
            word-break: break-all;
            line-height: 1.2rem;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }

            &-order {
                width: 10px;
                height: 10px;
                display: none;
                margin-left: 6px;
                right: 0;
                position: relative;

                svg {
                    fill: var(--basecolor);
                }
            }

            &.ordered-asc,
            &.ordered-desc {
                position: relative;

                .grid-list-item--col {
                    &-order {
                        display: inline-block;
                    }
                }
            }

            &.ordered-asc {
                .grid-list-item--col {
                    &-order {
                        transform: rotate(180deg);
                        transform-origin: 50%;
                        top: 8px;
                    }
                }
            }
        }

        &--header {
            padding: 12px 8px;
            font-weight: bold;
            font-size: 12px;
            background-color: #696c71;
            color: var(--basecolor);
            position: sticky;
            top: 60px;
            cursor: auto;
            z-index: 5;

            &:hover {
                background-color: #696c71;
            }
        }
    }
}

.icon {
    width: 18px;
    height: 18px;
}

#modal,
#additional-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    pointer-events: none;
    padding: 20px;

    .modal {

        &-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(99, 69, 62, 0.2);
            pointer-events: all;
            z-index: 1;
        }

        &-inner {
            background-color: var(--basecolor);
            position: relative;
            z-index: 2;
            padding: 20px 0;
            border-radius: 10px;
            width: 100%;
            height: auto;
            max-width: 840px;
            pointer-events: all;
            max-height: calc(100vh - 40px);
            box-shadow: 0 3px 16px rgba(black, .08);
        }

        &-header {
            padding: 0 20px;
        }

        &-content {
            overflow-y: scroll;
            height: 100%;
            max-height: calc(100vh - 200px);
            padding: 0 20px;
        }

        &-title {
            margin: 0 0 20px 0;
            font-size: 16px;
        }

        &-close {
            width: 20px;
            height: 20px;
            position: absolute;
            -webkit-appearance: none;
            background: none;
            border: none;
            box-shadow: none;
            top: 20px;
            right: 20px;
            cursor: pointer;
        
            &::after,
            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background-color: var(--primary);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }
        
            &::before {
              transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            }
        
            &:hover {
              opacity: .6;
            }
        }

        &--add-material {
            .field-col {
                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        &--create-deliverynote {
            max-width: 540px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &--content {
                flex: 1;
                z-index: 3;
                position: relative;
                min-height: 330px;
            }

            .modal-actions {
                z-index: 1;
                position: relative;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 20px 0;
        }
    }

    .delivery-note-pdf-modal {
        max-width: 1000px;
        .pdf-viewer {
            flex: 1;
        }
    }
}

#additional-modal {
    z-index: 100;
}

#confirm-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    pointer-events: none;
    padding: 20px;

    .modal {

        &-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(99, 69, 62, 0.2);
            pointer-events: all;
            z-index: 1;
        }

        &-inner {
            background-color: var(--basecolor);
            position: relative;
            z-index: 2;
            padding: 20px 0;
            border-radius: 10px;
            width: 100%;
            height: auto;
            max-width: 440px;
            pointer-events: all;
            max-height: calc(100vh - 40px);
            box-shadow: 0 3px 16px rgba(black, .08);
        }

        &-header {
            padding: 0 20px;
        }

        &-content {
            overflow-y: scroll;
            height: 100%;
            max-height: calc(100vh - 200px);
            padding: 0 20px;
        }

        &-title {
            margin: 0 0 20px 0;
            font-size: 16px;
        }

        &-close {
            width: 20px;
            height: 20px;
            position: absolute;
            -webkit-appearance: none;
            background: none;
            border: none;
            box-shadow: none;
            top: 20px;
            right: 20px;
            cursor: pointer;
        
            &::after,
            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background-color: var(--primary);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }
        
            &::before {
              transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            }
        
            &:hover {
              opacity: .6;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 20px 0;
        }
    }
}

.select-option {
    padding: 4px 8px;

    &--focused {
        background-color: var(--boxcolor);
    }

    &--color {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-right: 10px;
    }

    &--production-option {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    &--status-option {
        font-size: 14px;
    }

    &--material-option {
        & > div {
            span {
                flex: 1;
            }
        }
    }
}

.field {

    &-item {
        display: flex;
        padding: 6px 0;
        border-bottom: 1px solid rgba(black, .08);

        &--label {
            flex: 1;
            margin-right: 20px;
        }

        &--value {
            font-weight: 600;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.table-wrapper {
    width: 100%;
    overflow-x: scroll;
}

.table-container {
    display: flex;
}

.table-controls {
    padding-top: 10px;
    margin-right: 20px;

    .btn {
        margin-bottom: 10px;
        width: 100%;
    }

    .field-row {
        .btn {
            flex: 1;
            &-icon {
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
}

.filter-table {

    table {

        th {
            position: sticky;
            top: 50px;
            background-color: var(--basecoloralt);
            z-index: 3;
            min-width: 100px;

            span {
                display: block;
                font-size: 11px;
                margin-bottom: 6px;
                margin-top: 3px;
                cursor: pointer;
            }
            input {
                box-sizing: border-box;
                width: 100%;
                font-size: 12px;
                -webkit-appearance: none;
                border: 1px solid var(--bordercoloralt);
                background-color: var(--basecolor);
                border-radius: 4px;
                padding: 3px 6px;
                color: var(--fontcolor);

                &:focus {
                    outline: none;
                    border-color: var(--primarylight);
                }
            }
        }

        tbody {
            td {
                min-width: 100px;
                white-space: normal;
            }
        }
    }

    &-row {
        &--selected {
            td {
                background-color: var(--basecoloralt);
                color: var(--primarylight);

                &:first-child {
                    border-left: 3px solid var(--primarylight);
                }
            }
        }
    }
}

table.order-rows,
table {
    margin-bottom: 20px;
    width: 100%;

    th, td {
        font-size: 12px;
        white-space: nowrap;
        padding: 4px 8px;
        text-align: center;

        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }

    thead {
        tr {
            background-color: var(--basecoloralt);
            th {
                padding-bottom: 10px;
            }
        }
    }

    &.input-table {
        tbody {
            tr {
                cursor: default;
            }
        }
    }

    tbody {
        tr {
            cursor: pointer;
    
            &:hover {
                background-color: var(--boxcolor);
            }

            &.table-row--selected {
                background-color: var(--boxcolor);
            }
        }

        td {
            padding: 6px;
            position: relative;

            .btn {
                font-size: 12px;
                padding: 4px 8px;
            }

            .table-input {
                &--hidden {
                    opacity: 0;
                    pointer-events: none;
                }
            }

            .input-wrapper {
                margin-bottom: 0;
                position: absolute;
                left: 0;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);

                &--control {
                    &-focused {
                        .input-wrapper--input {
                            position: relative;
                            box-shadow: none;
                            border-color: var(--primarylight);
                            border-radius: 4px;
                            border: none;
            
                            input,
                            textarea {
                                border-color: transparent;
                            }
                        }
                    }
                }
                input:not([type=checkbox]) {
                    padding: 0;
                    border: none;
                    font-size: 12px;
                    background-color: transparent;
                    text-align: center;
                }
            }
        }
    }
}

.printouts,
.invoice-rows {

    table {
        width: 100%;
    }

    &-actions {
        display: flex;
        align-items: flex-end;
        margin-top: 20px;

        .input-wrapper {
            margin-bottom: 0;
            width: 100%;
        }

        .btn {
            min-height: 32px;
            margin-left: 20px;
        }
    }
}

.product-search {
    &--inner {
        display: flex;
        align-items: center;

        .input-wrapper {
            margin-bottom: 0;
            min-width: 300px;
            margin-right: 20px;
        }

        .btn {
            min-height: 38px;
        }
    }
}

.pagination {
    display: flex;
    justify-content: space-between;
    padding: 4px 20px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: var(--basecolor);
    box-shadow: 0 -3px 8px rgba(black, .08);
  
    &-prev,
    &-next {
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
  
      &:hover {
        color: var(--fontcoloralt);
      }
    }
  
    &-pages {
      background-color: var(--basecoloralt);
      padding: 5px 10px;
      border-radius: 6px;
      font-size: 14px;
      pointer-events: none;
    }
  
    .disabled {
      opacity: 0;
      pointer-events: none;
    }
}

.search {

    &-inner {
        position: relative;

        .input-wrapper {
            input {
                padding-right: 34px;
            }
        }
    }

    &-icon {
        width: 18px;
        height: 18px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        svg {
            fill: #979da7;
        }
    }
}

.react-datepicker {
    border-color: var(--bordercolor);
    box-shadow: 0 3px 16px rgba(black, .2);
    z-index: 999;

    &__header {
        background-color: var(--basecoloralt);
        border-bottom-color: var(--bordercolor);
    }

    &__navigation-icon {
        &::before {
            border-color: var(--primarylight);
        }
    }

    &__day {
        color: var(--fontcolor);

        &-name {
            color: var(--fontcolor);
        }

        &:hover {
            background-color: var(--boxcolor);
        }

        &--selected,
        &--keyboard-selected {
            background-color: var(--primarylight);

            &:hover {
                background-color: var(--primary);
            }
        }
    }

    &__month {
        &-container {
            background-color: var(--basecolor);
        }
    }

    &__current {
        &-month {
            color: var(--fontcolor);
        }
    }

    &-popper[data-placement^=bottom]{

        .react-datepicker {
            &__triangle {
                margin-left: -14px;

                &::after {
                    border-bottom-color: var(--basecoloralt);
                }

                &::before {
                    border-bottom-color: var(--bordercolor);
                }
            }
        }
    }
}

.filters {
    position: relative;
    z-index: 6;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;

    .input-wrapper {
        margin-bottom: 0;
    }

    & > div {
        margin-right: 20px;
    }

    &-date {
        display: flex;
        margin-right: 20px;

        .input-wrapper {
            &:last-child {
                margin-left: 20px;
            }
        }
    }

    &-name,
    &-status {
        flex: 0 0 180px;
    }
}

.production-status {
    color: var(--fontcolor);

    &--shipped {
        td {
            background-color: #86d7b0;
        }
    }
    &--overdue {
        td {
            background-color: #ff6565;
        }
    }
    &--ready-not-shipped {
        td {
            background-color: #81c71a;
        }
    }
    &--transport {
        td {
            background-color: #ff58faf7;
        }
    }
    &--ready {
        td {
            background-color: #a4f7fd;
        }
    }
    &--unpaid {
        td {
            background-color: #fef711;
        }
    }
    &--pending {
        td {
            background-color: #cccccc;
        }
    }
    &--dept {
        td {
            background-color: #a32f41;
        }
    }
    &--in-production {
        td {
            background-color: var(--basecolor);

            .theme--dark & {
                color: var(--fontcolor);
            }
        }
    }

    .theme--dark & {
        color: var(--basecoloralt);
    }
}

.factories,
.boxes,
.classes,
.extra-products,
.materials-screen,
.constants {

    .search {
        padding: 0 20px;
    }
    
    .table-wrapper {
        height: calc(100vh - 212px);
        overflow: scroll !important;

        th {
            position: sticky;
            top: 0 !important;
            background-color: var(--basecoloralt);
            z-index: 2;
        }
    }
}