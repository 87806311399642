.invoice {

    .field-row {

        .field-col {
            &:first-child {
                margin-right: 20px;
            }
        }
    }

    .invoice-fields {
        background-color: var(--boxcolor);
        padding: 20px;
        border-radius: 10px;

        .creator {
            justify-content: flex-start;

            .field-item {
                &--label {
                    flex: 0 1 auto;
                }
            }
        }
    }

    .invoice-rows {
        margin-top: 40px;
    }

    .product-search {
        margin-bottom: 40px;
    }
}