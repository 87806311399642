.orders {

    .grid-list-item {
        grid-template-columns: 40px repeat(5, 1fr);
        align-items: center;

        &--col {

            &:nth-child(2) {
                text-align: left;

            }
        }
    }
    
    .filter-table {
        height: calc(100vh - 134px);
        overflow-x: scroll;

        td {
            white-space: nowrap;
            max-width: 100%;
        }

        th {
            top: 0;
        }

    }
}