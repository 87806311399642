.sales {

    &-fields {
        padding: 0 20px;

        h5 {
            margin-bottom: 8px;
        }
        
        .machines-list {
            .box-fields--workflow {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    .input-wrapper {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .top-fields {
        border-bottom: 1px solid var(--boxcolor);
    }

    .box-fields,
    .sales-fields,
    .client-fields {
        margin-bottom: 0;

        .field-row--contact {
            justify-content: stretch;
            align-items: center;

            .input-wrapper {
                flex: 1;
                margin-right: 10px;
            }

            .btn {
                margin-top: 8px;
            }
        }

        & > .field-row {
            background-color: var(--basecoloralt);
            border-radius: 10px;
        }

        .field-col {
            background-color: var(--basecoloralt);
            padding: 10px;
            border-radius: 10px;

            &--box {
                max-width: 400px;
            }

            .field-col {
                padding: 0;
            }
        }

        &-item {
            display: flex;
            padding: 6px 0;
            border-bottom: 1px solid rgba(black, .08);

            &--label {
                flex: 1;
                margin-right: 20px;
            }

            &--value {
                font-weight: 600;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .box-fields {
        
        &-item {
            font-size: 12px;
        }
    }

    .client-fields {
        margin-top: 5px;

        h5 {
            margin-top: 0;
        }

        &-item {
            border-bottom: none;

            &--orderno {
                justify-content: flex-start;

                .client-fields-item--label {
                    flex: 0 1 auto;
                }
            }
        }

        .field-col.client-checkboxes {
            padding: 0;
            .input-wrapper--checkbox {
                margin: 5px 0;
            }
        }
    }

    .sales-fields {
        .client-fields {

            h5 {
                margin-top: 8px;
            }
            .field-col {
                padding-top: 0;
                padding-right: 20px;

                .field-row {
                    .field-col {
                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }

    .editable-fields {
        background-color: var(--basecoloralt);
        border-radius: 10px;

        .btn {
            font-size: 12px;
            min-height: 32px;
        }

        .field-row:not(.flex-wrap--wrap) {
            margin-bottom: 0;

            & > .field-col {
                flex: 1 1 50%;
            }
        }

        .field-col--full {
            padding: 0 20px;

            .printouts {
                margin-top: 0;
                padding: 20px 0;
                border-top: 1px solid #ccc;
                border-radius: 0;
            }
            
            &:first-child {
                margin-right: 0;
            }
        }

        h5 {
            margin-top: 0;
        }

        .related-order {
            display: flex;
            align-items: flex-end;
            margin-bottom: 16px;

            .input-wrapper {
                margin-bottom: 0;
                margin-right: 16px;
            }
        }
    }

    .printouts {
        background-color: var(--basecoloralt);
        border-radius: 10px;
        margin-top: 20px;

        &-actions {
            margin-top: 0;
        }

        &-title {
            display: flex;
            justify-content: space-between;

            h5 {
                margin-right: 16px;
            }
        }
    }
}