.material-order {

    .table {

        &-actions {
            display: flex;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &-wrapper {
            border: 2px solid var(--boxcolor);
            padding: 10px;
            border-radius: 10px;

            .empty-result {
                margin-bottom: 0;
                padding: 10px 0;

                span {
                    color: var(--primarylight);
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .invoice-fields {
        background-color: var(--boxcolor);
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 40px;

        h5 {
            margin-top: 0;
        }

        .field-row {

            .field-col {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                &:not(.field-col--factory) {
                    max-width: 200px;
                }
            }
        }

        .field-item {
            border-bottom: none;

            &--label {
                flex: 0 0 140px
            }

            &--checkbox {
                padding: 0;
            }
        }
    }
}

.modal--add-material {
    .production-data {
        background-color: var(--boxcolor);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 20px;

        h5 {
            margin: 0 0 6px;
        }

        .field-item {
            font-size: 12px;
        }
    }

    .field-col {

        &--asap {
            margin-left: 20px;
        }

        &--remark {
            textarea {
                min-height: 60px;
            }
        }
    }
}