.invoices {

    &-item {
        display: grid;
        grid-template-columns: 30px repeat(12, 1fr);

        &.ordered {
            color: rgb(15, 172, 119);
        }

        &--ordered {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .grid-list-item {

        .icon {
            svg {
                fill: rgb(15, 172, 119);
            }
        }
    }
}