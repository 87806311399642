.material-report {

    .filter-table {
        height: calc(100vh - 135px);
        overflow-x: scroll;

        td {
            white-space: nowrap;
            max-width: 100%;
        }

        th {
            top: 0;
        }

    }
}