.sales {


    .filters,
    .inner-title {
        position: sticky;
        left: 0;
        background-color: var(--basecolor);
        z-index: 8;
        padding-top: 6px;
        padding-bottom: 6px;
    }
    
    .inner-title {
        top: 50px;
    }

    .filters {
        top: 96px;
    }

    .filter-table {
        height: calc(100vh - 212px);
        overflow-x: scroll;

        td {
            white-space: nowrap;
            max-width: 100%;
        }

        th {
            top: 0;
        }

    }

    .grid-list-item {
        grid-template-columns: 30px repeat(8, 1fr);

        .input-wrapper--checkbox {

            input {
                width: 24px;
                height: 24px;
            }
        }
    }
}

#modal .make-order-modal {
    max-width: 100%;
}