.delivery-notes {

    &-item {
        display: grid;
        grid-template-columns: 30px 70px repeat(4, 1fr) 80px 90px 120px;

        &.ordered {
            color: rgb(15, 172, 119);
        }

        &--ordered {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .grid-list-item {

        .icon {
            svg {
                fill: rgb(15, 172, 119);
            }
        }
    }

    .filter-table {
        height: calc(100vh - 134px);
        overflow-x: scroll;

        td {
            white-space: nowrap;
            max-width: 100%;
        }

        th {
            top: 0;
        }
    }
}