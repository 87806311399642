.pdf-viewer {

    &--actions {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .inner {

        iframe {
            width: 100%;
            height: calc(100vh - 50px);
            border: none;
            position: relative;
        }
    }
}