.report-sales {

    .inner-title {
        & > div {
            margin-right: 20px;
        }
    }

    .chart-wrapper {
        max-width: 1000px;
        margin: 0 auto;
    }
}