.material-orders {

    .grid-list-item {
        grid-template-columns: repeat(5, 1fr) 80px;

        &.ordered {
            color: rgb(15, 172, 119);
        }

        &--ordered {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .material-order--ordered {
        td {
            background-color: #81c71a !important;

            .theme--dark & {
                background-color: #1d442c !important;
            }
        }

        &.filter-table-row--selected {
            td {
                color: var(--basecolor);
            }
        }
    }

    .filter-table {
        height: calc(100vh - 134px);
        overflow-x: scroll;

        td {
            white-space: nowrap;
            max-width: 100%;
        }

        th {
            top: 0;
        }

    }

    .grid-list-item {

        .icon {
            svg {
                fill: rgb(15, 172, 119);
            }
        }
    }
}