.report-one-company {

    .inner-title {
        & > div {
            margin-right: 20px;
        }

        .client-search {
            .input-wrapper {
                min-width: 300px;
            }
        }
    }
}