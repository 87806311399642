.constants {

    .field-row {
        background-color: var(--basecoloralt);
        margin-bottom: 20px;
        border-radius: 10px;
        .field-col {
            padding: 20px;
        }
    }
}