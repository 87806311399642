.production {

    &-fields {

        .editable-fields {

            .production-fields {
                &--info {
                    max-width: 170px;
                }
        
                &--comment {
                    max-width: 200px;
                }
        
                &--machines-list {
                    flex: 0 1 200px;
                }

                &--tables {
                    padding-left: 20px;
                }

                &--printout {
                    h5 {
                        margin-top: 0;
                    }

                    .btn {
                        font-size: 12px;
                    }
                }

                &--delivery-notes {
                    border-bottom: 1px solid #ccc;

                    & > h5 {
                        margin-top: 16px;
                    }
                }
            }
        }
        
        .machines-list {
            margin-left: 20px;

            .input-wrapper {
                margin-right: 10px;
            }

            .box-fields--workflow {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            h5 {
                margin: 0 0 16px;
            }
        }

        .measurement-item {
            margin-top: 16px;
        }

        .input-wrapper {

            &--checkbox {
                padding: 6px 0;
                label {
                    align-items: center;
                }
            }
        }
    }

    .box-fields,
    .sales-fields,
    .client-fields {

        .field-col {
            background-color: var(--basecoloralt);
            padding: 20px;
            border-radius: 10px;

            &:first-child {
                margin-right: 20px;
            }
        }

        &-item {
            display: flex;
            padding: 6px 0;
            border-bottom: 1px solid rgba(black, .08);

            &--label {
                flex: 1;
                margin-right: 20px;
            }

            &--value {
                font-weight: 600;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .box-fields {
        background-color: var(--basecoloralt);
        border-radius: 20px;
        margin-right: 20px;

        .field-col {

            &:first-child {
                padding-right: 0;
            }

            &:last-child {
                padding-left: 0;
            }
        }
    }

    .sales-fields {
        .field-row {
            margin-bottom: 20px;
        }
    }

    .client-fields {
        &-item {
            border-bottom: none;

            &--orderno {
                justify-content: flex-start;

                .client-fields-item--label {
                    flex: 0 1 auto;
                }
            }
        }
    }

    .editable-fields {
        background-color: var(--basecoloralt);
        padding: 20px;
        border-radius: 10px;

        .field-col {
            
            &:first-child {
                margin-right: 20px;
            }

            .input-wrapper {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .printouts {
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;

        &-title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;

            h5 {
                margin: 0 16px 0 0;
            }
        }
    }

    .printout-row {

        .field-col {
            &:first-child {
                margin-right: 20px;
            }
        }
    }

    .delivery-note-rows {
        h5 {
            margin-top: 0;
        }

        table {
            width: 100%;

            .order-rows--header-item {
                &:first-child {
                    padding-left: 0;
                }
            }
        }

        .delivery-note-total {
            padding-bottom: 10px;

            span {
                &:last-child {
                    font-weight: bold;
                    margin-left: 20px;
                }
            }
        }

        .react-datepicker__input-container {
            input:not([type=checkbox]) {
                text-align: left;
            }
        }
    }

    .factory-materials {

        .factory-order-rows {
            z-index: 3;
            position: relative;
        }
    }

    .material-delivery {
        margin-top: 16px;

        h5 {
            margin-top: 0;
        }

        .product-search {
            
            .btn {
                font-size: 12px;
                margin-left: auto;
                padding: 4px 10px;
                min-height: 28px;
            }

            .input-wrapper {
                min-width: 0;
                margin-right: 4px;

                input {
                    font-size: 12px;
                }
            }
        }

        .empty-result {
            margin-top: 20px;
        }

        table {
            width: 100%;
            margin-top: 20px;
        }
    }

    .box-row {
        h5 {
            margin: 20px 0 6px;
        }

        .box-fields,
        .client-fields,
        .sales-fields {

            &-item {
                font-size: 14px;
            }
        }
    }
}