.delivery-note {

    .field-row {

        .field-col {
            &:first-child {
                margin-right: 20px;
            }
        }
    }

    .delivery-note-rows {
        margin: 0 20px;
    }

    .delivery-note-fields {
        background-color: var(--boxcolor);
        padding: 20px;
        border-radius: 10px;
        margin: 0 20px;

        .creator {
            justify-content: flex-start;

            .field-item {
                &--label {
                    flex: 0 1 auto;
                }
            }
        }

        .pallets {
            display: flex;
            align-items: center;

            .input-wrapper--checkbox {
                margin-left: 20px;
            }
        }
    }

    .invoice-rows {
        margin-top: 40px;
    }

    .product-search {
        margin-bottom: 40px;

        .amount {
            min-width: 0;
            max-width: 130px;
        }
    }

    .order-rows--header-item {
        &:first-child {
            width: 400px;
        }
    }

    table {
        width: 100%;
    }
}