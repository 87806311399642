.extra-products {

    .table-wrapper {
        width: auto;
        overflow: initial;

        th {
            position: sticky;
            top: 60px;
            background-color: var(--basecoloralt);
            z-index: 2;
        }

        td, th {
            text-align: left;

            .input-wrapper {
                input {
                    text-align: left;
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }
        }
    }

    &-actions {
        position: sticky;
        bottom: 16px;

        .btn {
            margin-right: 10px;
        }
    }
}