html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu', sans-serif;
    color: var(--fontcolor);
    background-color: var(--basecolor);
}

* {
    box-sizing: border-box;
}

.app-content {
    padding-top: 42px;
    position: relative;

    .inner {
        margin: 0 auto;
        padding-top: 10px;

        &-title {
            display: flex;
            align-items: center;
            margin: 5px 0;
            padding: 5px 20px;

            h2 {
                margin: 0 40px 0 0;
            }

            &--actions {
                display: flex;
                flex-wrap: wrap;

                .btn {
                    margin-right: 10px;
                }
            }
        }
    }
}

.error-view {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    &--inner {
        text-align: center;
    }
}