.client {

    &-item {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .table-wrapper {

        td {

            &:first-child {
                input {
                    text-align: left;
                    padding-left: 8px;
                }
            }

            &:last-child {
                input {
                    text-align: right;
                    padding-right: 8px;
                }
            }
        }
    }
}