.login {
    background-color: rgba(244, 228, 224, .2);
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .theme--dark & {
        background-color: rgba(54, 54, 54, .2);
    }

    .inner {
        background-color: var(--basecolor);
        padding: 20px;
        max-width: 460px;
        border-radius: 10px;
        width: 100%;
        box-shadow: 0 5px 20px rgba(black, .08);
        margin: 20px;

        .brand {

            &-logo {
                width: 48px;
                height: 48px;
                background-image: url('../../assets/logo.png');
                background-repeat: no-repeat;
                background-size: contain;
                margin: 0 auto 20px;
            }
        }
    }

    &-actions {
        display: flex;
        justify-content: flex-end;

        .btn {
            width: 100%;
            padding: 14px;
        }
    }
}