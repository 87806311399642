.margin-right-0 {
    margin-right: 0 !important;
}

.align-items--center {
    align-items: center;
}

.justify-content--flex-end {
    justify-content: flex-end !important;
}

.justify-content--flex-start {
    justify-content: flex-start !important;
}

.justify-content--space-between {
    justify-content: space-between !important;
}

.flex {
    display: flex;
}

.flex-wrap {

    &--wrap {
        flex-wrap: wrap;
    }
}

.field-col {

    &--full {
        flex: 0 0 100% !important;
    }
}