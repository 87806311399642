:root {

    .theme--light {
        --basecolor: #ffffff;
        --basecoloralt: #f9f9fb;

        --boxcolor: #f4f4f6;

        --primary: #992825;
        --primarylight: #BE4D4A;
        --primarydark: #61403F;

        --fontcolor: #242425;
        --fontcoloralt: #4b4b4d;

        --bordercolor: #e0e4e9;
        --bordercoloralt: #c9cace;

        --buttoncolor: #fff;
    }

    .theme--dark {
        --basecolor: #1d1e1e;
        --basecoloralt: #252626;

        --lightgrey: #c9cace;
        --boxcolor: #3e3e3e;

        --primary: #992825;
        --primarylight: #BE4D4A;
        --primarydark: #61403F;

        --fontcolor: #dfdfe6;
        --fontcoloralt: #9c9ca2;

        --bordercolor: #424344;
        --bordercoloralt: #48494a;

        --buttoncolor: #fff;
    }
}